var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mb-2", attrs: { cols: "12", md: "4" } },
            [
              _c(
                "b-input-group",
                [
                  _c(
                    "b-input-group-prepend",
                    { attrs: { "is-text": "" } },
                    [_c("feather-icon", { attrs: { icon: "SearchIcon" } })],
                    1
                  ),
                  _c("b-form-input", {
                    attrs: { placeholder: "Email" },
                    model: {
                      value: _vm.filters["email"],
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "email", $$v)
                      },
                      expression: "filters['email']"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("b-col", { attrs: { cols: "12", md: "3" } }),
          _c(
            "b-col",
            { attrs: { cols: "12", md: "5" } },
            [
              _c(
                "b-form-group",
                {},
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "btn-sm-block  float-right ml-1 mr-1 mb-1  text-white",
                      attrs: { variant: "dark" },
                      on: {
                        click: function($event) {
                          return _vm.callparentfunction()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "align-middle" }, [
                        _vm._v("Invite Staff")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.tableLoading, rounded: "sm" } },
                [
                  _c("b-table", {
                    ref: "table",
                    staticClass: "mobile_table_css",
                    attrs: {
                      "current-page": _vm.currentPage,
                      fields: _vm.fields,
                      filter: _vm.filter,
                      "filter-included-fields": _vm.filterOn,
                      items: _vm.getInvitations,
                      "per-page": _vm.pagination.perPage,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                      "sort-direction": _vm.sortDirection,
                      hover: "",
                      responsive: ""
                    },
                    on: {
                      "update:sortBy": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function($event) {
                        _vm.sortDesc = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(full_name)",
                        fn: function(data) {
                          return [
                            _c(
                              "ul",
                              { staticClass: "list-inline mb-0" },
                              [
                                _c("li", { staticClass: "list-inline-item" }, [
                                  _vm._v(" " + _vm._s(data.item.full_name))
                                ]),
                                data.item.is_locum
                                  ? _c(
                                      "b-badge",
                                      {
                                        staticClass: "ml-1",
                                        staticStyle: { padding: "8px" },
                                        attrs: { variant: "light-info" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-weight-700"
                                          },
                                          [_vm._v(" L ")]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(action)",
                        fn: function(data) {
                          return [
                            _c(
                              "div",
                              [
                                data.item.state !== "accepted"
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "btn-sm-block  text-primary",
                                        attrs: { variant: "light" },
                                        on: {
                                          click: function($event) {
                                            return _vm.resendInvitation(
                                              data.item.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "mr-1",
                                          attrs: { icon: "SendIcon" }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "align-middle" },
                                          [_vm._v("Send Again")]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _vm.pagination.totalRows === 0
                ? _c("NoDataRow", {
                    attrs: { messgae: "No Invitations to display" }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-center justify-content-sm-start",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _vm.pagination.totalRows !== 0
                ? _c("span", { staticClass: "text-muted" }, [
                    _vm._v(
                      "Showing " +
                        _vm._s(_vm.pagination.from) +
                        " to " +
                        _vm._s(_vm.pagination.to) +
                        " of " +
                        _vm._s(_vm.pagination.totalRows) +
                        " entries"
                    )
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "b-col",
            {
              staticClass:
                "d-flex align-items-center justify-content-center justify-content-sm-end",
              attrs: { cols: "12", sm: "6" }
            },
            [
              _c("b-pagination", {
                staticClass: "mb-0 mt-1 mt-sm-0 ",
                attrs: {
                  "per-page": _vm.pagination.perPage,
                  "total-rows": _vm.pagination.totalRows,
                  "first-number": "",
                  "last-number": "",
                  "next-class": "next-item",
                  "prev-class": "prev-item"
                },
                scopedSlots: _vm._u([
                  {
                    key: "prev-text",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronLeftIcon", size: "18" }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "next-text",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronRightIcon", size: "18" }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }