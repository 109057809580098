var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            size: "lg"
          },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "b-overlay",
            { attrs: { rounded: "sm" } },
            [
              _c(
                "b-container",
                { attrs: { fluide: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "svgImage" },
                    [
                      _c("b-avatar", {
                        staticClass: "badge-minimal",
                        attrs: {
                          size: "80",
                          variant: "",
                          badge: "",
                          src: require("@/assets/images/avatars/14.png"),
                          "badge-variant": "success"
                        }
                      })
                    ],
                    1
                  ),
                  _c("h4", { staticClass: "svgImage mt-2" }, [
                    _vm._v(" Invite Locum")
                  ]),
                  _c("validation-observer", {
                    ref: "InvitationSendForm",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var valid = ref.valid
                          return [
                            _c(
                              "div",
                              {},
                              [
                                _c(
                                  "b-form",
                                  {
                                    ref: "form",
                                    staticClass: "repeater-form",
                                    staticStyle: {
                                      "overflow-y": "visible !important"
                                    },
                                    style: { height: _vm.trHeight },
                                    on: {
                                      submit: function($event) {
                                        $event.preventDefault()
                                        return _vm.repeateAgain($event)
                                      }
                                    }
                                  },
                                  _vm._l(_vm.emails, function(item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        ref: "rowInvite",
                                        refInFor: true,
                                        attrs: { id: index }
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "mr-0",
                                                attrs: { md: "11" }
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: "Email",
                                                      "label-for": "email"
                                                    }
                                                  },
                                                  [
                                                    _c("validation-provider", {
                                                      ref: "email",
                                                      refInFor: true,
                                                      attrs: {
                                                        bails: false,
                                                        name: "Email" + index,
                                                        rules: "required|email"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var errors =
                                                                ref.errors
                                                              return [
                                                                _c(
                                                                  "b-input-group",
                                                                  {
                                                                    staticClass:
                                                                      "input-group-merge"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        attrs: {
                                                                          id:
                                                                            "name" +
                                                                            index,
                                                                          placeholder:
                                                                            "Enter email",
                                                                          type:
                                                                            "text"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .emails[
                                                                              index
                                                                            ],
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.emails,
                                                                              index,
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "emails[index]"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "small",
                                                                  {
                                                                    staticClass:
                                                                      "text-danger mr-lg-3"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass: "mb-50 pl-0 ",
                                                attrs: { md: "1" }
                                              },
                                              [
                                                _vm.emails.length > 1
                                                  ? _c(
                                                      "b-button",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "ripple",
                                                            rawName:
                                                              "v-ripple.400",
                                                            value:
                                                              "rgba(234, 84, 85, 0.15)",
                                                            expression:
                                                              "'rgba(234, 84, 85, 0.15)'",
                                                            modifiers: {
                                                              "400": true
                                                            }
                                                          },
                                                          {
                                                            name: "b-tooltip",
                                                            rawName:
                                                              "v-b-tooltip.hover.top",
                                                            value: "Cancel",
                                                            expression:
                                                              "'Cancel'",
                                                            modifiers: {
                                                              hover: true,
                                                              top: true
                                                            }
                                                          }
                                                        ],
                                                        staticClass:
                                                          "mt-0 mt-md-2 px-1",
                                                        attrs: {
                                                          variant: "flat-danger"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.removeItem(
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("feather-icon", {
                                                          attrs: {
                                                            icon: "XIcon",
                                                            size: "14"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              {},
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "11" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(255, 255, 255, 0.15)",
                                            expression:
                                              "'rgba(255, 255, 255, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        staticClass: "float-left m1-3 ",
                                        attrs: {
                                          variant: "outline-primary",
                                          size: "sm"
                                        },
                                        on: { click: _vm.repeateAgain }
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticClass: "mr-25 text-primary",
                                          attrs: { icon: "PlusIcon" }
                                        }),
                                        _c(
                                          "span",
                                          { staticClass: "text-primary" },
                                          [_vm._v("Add Locum")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "mb-lg-3 text-right mt-2",
                                    attrs: { cols: "12", md: "11" }
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(255, 255, 255, 0.15)",
                                            expression:
                                              "'rgba(255, 255, 255, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        staticClass: "mr-1",
                                        attrs: {
                                          type: "submit",
                                          variant: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.sendInvitationForm()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " Send Request" +
                                            _vm._s(
                                              _vm.emails.length > 1 ? "s" : ""
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(186, 191, 199, 0.15)",
                                            expression:
                                              "'rgba(186, 191, 199, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        attrs: {
                                          type: "reset",
                                          variant: "outline-secondary"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.emails = [""]
                                            _vm.initTrHeight()
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-secondary" },
                                          [_vm._v(" Reset ")]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }