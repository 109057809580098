<template>
  <div>
    <b-col class="text-center" cols="12">
      <div>
        <div class="d-flex justify-content-center mt-5 ">
          <div class="align-self-center mt-5 mb-1 ">
            <img alt="" class="img-fluid" src="@/assets/images/pages/user-icon.png">
          </div>
        </div>
        <div class="d-flex justify-content-center mb-5">
          <div class="align-self-center p-2 mb-5  ">
            <h4>{{ messgae }}</h4>
          </div>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import {
  BCol
} from 'bootstrap-vue'

export default {
  name: 'NoDataRow',
  components: {
    BCol,
  },
  props: {
    messgae: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
