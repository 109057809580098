var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { staticClass: "mb-2", attrs: { cols: "12", md: "4" } },
        [
          _c(
            "b-input-group",
            [
              _c(
                "b-input-group-prepend",
                { attrs: { "is-text": "" } },
                [_c("feather-icon", { attrs: { icon: "SearchIcon" } })],
                1
              ),
              _c("b-form-input", {
                attrs: { placeholder: "Search" },
                model: {
                  value: _vm.filters["full_name"],
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "full_name", $$v)
                  },
                  expression: "filters['full_name']"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "12", md: "3" } },
        [
          _c(
            "b-form-group",
            [
              _c("v-select", {
                attrs: {
                  options: _vm.jobRoleOption,
                  reduce: function(jobRoleOption) {
                    return jobRoleOption.id
                  },
                  label: "name",
                  placeholder: "All Roles",
                  value: "id"
                },
                model: {
                  value: _vm.filters["job_role_id"],
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "job_role_id", $$v)
                  },
                  expression: "filters['job_role_id']"
                }
              })
            ],
            1
          ),
          _vm._v(" " + _vm._s(_vm.filters["JobRole_id"]) + " ")
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "12", md: "5" } },
        [
          _c(
            "b-form-group",
            {},
            [
              _c(
                "b-button",
                {
                  staticClass:
                    "btn-sm-block float-right ml-1 mr-1 mb-1 text-white",
                  attrs: { variant: "dark" },
                  on: {
                    click: function($event) {
                      return _vm.callparentfunction()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "align-middle" }, [
                    _vm._v("Invite Staff")
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.tableLoading, rounded: "sm" } },
            [
              _c("b-table", {
                ref: "table",
                staticClass: "mobile_table_css",
                attrs: {
                  "current-page": _vm.currentPage,
                  fields: _vm.fields,
                  items: _vm.getUsers,
                  "per-page": _vm.pagination.perPage,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  "sort-direction": _vm.sortDirection,
                  hover: "",
                  responsive: ""
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "row-clicked": _vm.navigateStaffProfile
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(name)",
                    fn: function(data) {
                      return [
                        _c(
                          "ul",
                          { staticClass: "list-inline mb-0" },
                          [
                            _c(
                              "li",
                              { staticClass: "list-inline-item" },
                              [
                                _c("b-avatar", {
                                  staticClass: "pull-up",
                                  attrs: {
                                    src: _vm.getUserImage(data.item.id, true)
                                  }
                                })
                              ],
                              1
                            ),
                            _c("li", { staticClass: "list-inline-item" }, [
                              _vm._v(_vm._s(data.item.name))
                            ]),
                            _c("b-badge", {
                              staticClass: "ml-1",
                              staticStyle: { padding: "8px" },
                              attrs: { variant: "light-info" }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(total_hours_worked)",
                    fn: function(data) {
                      return [
                        _c("div", { staticClass: "list-inline-item" }, [
                          _vm._v(
                            " " +
                              _vm._s(data.item.total_hours_worked.toFixed(0))
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "head(first_name)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.top",
                                value: "Sort",
                                expression: "'Sort'",
                                modifiers: { hover: true, top: true }
                              }
                            ]
                          },
                          [_vm._v(_vm._s(data.label))]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _vm.pagination.totalRows === 0
            ? _c("NoDataRow", {
                attrs: { messgae: "No Salaried Staff to display" }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-col",
        {
          staticClass:
            "\n      d-flex\n      align-items-center\n      justify-content-center justify-content-sm-start\n    ",
          attrs: { cols: "12", sm: "6" }
        },
        [
          _vm.pagination.totalRows !== 0
            ? _c("span", { staticClass: "text-muted" }, [
                _vm._v(
                  "Showing " +
                    _vm._s(_vm.pagination.from) +
                    " to " +
                    _vm._s(_vm.pagination.to) +
                    " of " +
                    _vm._s(_vm.pagination.totalRows) +
                    " entries"
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "b-col",
        {
          staticClass:
            "\n      d-flex\n      align-items-center\n      justify-content-center justify-content-sm-end\n    ",
          attrs: { cols: "12", sm: "6" }
        },
        [
          _c("b-pagination", {
            staticClass: "mb-0 mt-1 mt-sm-0",
            attrs: {
              "per-page": _vm.pagination.perPage,
              "total-rows": _vm.pagination.totalRows,
              "first-number": "",
              "last-number": "",
              "next-class": "next-item",
              "prev-class": "prev-item"
            },
            scopedSlots: _vm._u([
              {
                key: "prev-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { icon: "ChevronLeftIcon", size: "18" }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "next-text",
                fn: function() {
                  return [
                    _c("feather-icon", {
                      attrs: { icon: "ChevronRightIcon", size: "18" }
                    })
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }