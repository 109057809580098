var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-col", { staticClass: "text-center", attrs: { cols: "12" } }, [
        _c("div", [
          _c("div", { staticClass: "d-flex justify-content-center mt-5 " }, [
            _c("div", { staticClass: "align-self-center mt-5 mb-1 " }, [
              _c("img", {
                staticClass: "img-fluid",
                attrs: {
                  alt: "",
                  src: require("@/assets/images/pages/user-icon.png")
                }
              })
            ])
          ]),
          _c("div", { staticClass: "d-flex justify-content-center mb-5" }, [
            _c("div", { staticClass: "align-self-center p-2 mb-5  " }, [
              _c("h4", [_vm._v(_vm._s(_vm.messgae))])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }